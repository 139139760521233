<!--
 * @Author: your name
 * @Date: 2020-10-15 16:40:36
 * @LastEditTime: 2021-07-22 14:00:46
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/Home/index.vue
-->
<template>
  <div v-if="dataInfo" class="home-page">
    <!-- 已绑定+未缴清 205px  已绑定+已缴清165px  -->
    <div
      :style="{ height: dataInfo.isBind && dataInfo.isPay ? '175px' : '165px' }"
      :class="dataInfo.isCertified ? 'home-top-content-realName' : 'home-top-content'"
    >
      <div class="home-header">
        <div class="leftInfo">
          <div class="imgDiv">
            <img :src="dataInfo.headPortrait" alt="" />
          </div>
          <!--<span >{{ dataInfo.nickName }}</span>-->
          <span v-if="dataInfo.certifyType != '0'">
            <em v-if="dataInfo.isCertified">
              <img src="../../assets/icon/ic_已认证@2x.png" alt="" />
            </em>
            <em @click="goToRealName" v-else>
              <img src="../../assets/icon/ic_未认证@2x.png" alt="" />
            </em>
          </span>
        </div>
        <!-- 是否认证 -->
        <div class="rightSeting" v-if="dataInfo.isCertified">
          <span class="tel-text" @click="showCallPhone">查看服务热线</span>
          <em @click="showCallPhone"><img src="../../assets/icon/ic_客服_white@2x.png" alt=""/></em>
          <em @click="gotoSetting"><img src="../../assets/icon/ic_设置_white@2x.png" alt=""/></em>
        </div>
        <div class="rightSeting" v-else>
          <span class="tel-text-no" @click="showCallPhone">查看服务热线</span>
          <em @click="showCallPhone"><img src="../../assets/icon/ic_客服@2x.png" alt=""/></em>
          <em @click="gotoSetting"><img src="../../assets/icon/ic_设置@2x.png" alt=""/></em>
        </div>
      </div>
      <!-- 已绑定  -->
      <div class="bind-info" v-if="dataInfo.isBind">
        <div class="bind" v-if="dataInfo.isPay">
          <!-- <div class="bind-time-info">
            <van-icon name="info-o" class="infoIcon" />
            <span>最后缴费日期: </span>
            <em>{{ dataInfo.finalPayTime }}</em>
          </div> -->
          <div class="bind-pay-info">
            <p>欠费金额</p>
            <div class="bind-bottom-info">
              <span>¥{{ isActual(dataInfo.paymentAmount) }}</span>
              <!-- <div class="bind-quick-payment">
                <em><img src="../../assets/icon/ic_living_expenses@2x.png" alt=""/></em>
                <span>快捷缴费</span>
                <van-icon name="arrow" class="arrowIcon" />
              </div> -->
            </div>
          </div>
        </div>

        <div class="payUp" v-else>
          <p>暂无欠费~</p>
        </div>
      </div>
      <!-- 未绑定 -->
      <div class="noBind-info" v-else @click="bindFn">
        <div class="noBind">
          <div class="noBind-txt">
            <p>您还未绑定任何水表</p>
            <span>立即绑定享受用水的便捷之旅吧~</span>
          </div>
          <div class="noBind-quick-payment">
            <em><img src="../../assets/icon/ic_living_expenses@2x.png" alt=""/></em>
            <span>立即绑定</span>
            <van-icon name="arrow" class="arrowIcon" />
          </div>
        </div>
      </div>
    </div>

    <div class="home-bottom-content">
      <!-- 已绑定状态下-我的用水 -->
      <myWater-Bind
        v-if="authorInfo && dataInfo.isBind"
        :topDataInfo="authorInfo"
        :dataInfo="dataInfo.isCertified ? girdGoldenIcon : girdIcon"
        :isRealNameFlag="dataInfo.isCertified"
      />
      <!-- 未绑定状态下-我的用水 MyWaterUnbound-->
      <myWater-Unbound
        v-else
        :dataInfo="dataInfo.isCertified ? girdGoldenIcon : girdIcon"
        :isRealNameFlag="dataInfo.isCertified"
      />
      <!-- 我的办理 -->
      <business-Process
        v-if="businessIcon && businessIcon.length"
        :dataInfo="businessIcon"
        :businessNum="businessNum"
      />
      <!-- 我的反馈  -->
      <feed-Back :isRealNameFlag="dataInfo.isCertified" :feedBackNum="feedBackNum" />

      <!-- 我的合同  -->
      <contract-signing
        v-if="contractNum != 0"
        :isRealNameFlag="dataInfo.isCertified"
        :contractNum="contractNum"
        :unsignNum="unsignNum"
      />
    </div>

    <!-- 拨打电话 -->
    <tel-popup :showCallFlag="showCall" @clickCancelTel="cancelTel" />

    <!-- <div
      :class="{ 'tab-active': activeIndex === index }"
      v-for="(item, index) in netHallNoticeType"
      :key="item.code"
      @click="jumpToNotice(item, index)"
    >
      {{ item.name }}
    </div> -->
  </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { formatTime } from '@/utils/tools';
import TelPopup from './module/telPopup';
import ContractSigning from './module/contractSigning'; // 我的合同
import FeedBack from './module/feedBack'; // 我的反馈
import BusinessProcess from './module/businessProcess'; // 我的办理
import MyWaterUnbound from './module/myWaterUnbound'; // 我的用水-未绑定
import MyWaterBind from './module/myWaterBind'; // 我的用水-已绑定
import { selectByCustRealId, selectMyWater, selectProcessingCount } from '../../api/home';
import { selectBusHandlingPage } from '@/api/business';
import { girdIcon, girdGoldenIcon, businessIcon } from './components/business';
import store from '@/store';
import { mapState } from 'vuex';
import { isAmount } from '@/utils/validator';
import { getAllContract } from '@/api/contract';

export default {
  name: 'Home',
  components: {
    ContractSigning,
    FeedBack,
    BusinessProcess,
    MyWaterUnbound,
    TelPopup,
    MyWaterBind,
  },
  data() {
    return {
      show: false,
      dataInfo: null,
      authorInfo: [],
      girdIcon: girdIcon,
      girdGoldenIcon: girdGoldenIcon,
      businessIcon: businessIcon,
      showCall: false,
      feedBackNum: 0,
      businessNum: 0,
      contractNum: 0,
      unsignNum: 0,
      activeIndex: 0
    };
  },
  computed: {
    ...mapState('dict', {
      dictory: (state) => state.dictory,
    }),
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    },
    netHallNoticeType() {
      if (this.dictory) {
        let data = this.dictory.NetHallNoticeType.child;
        return data;
      }
      return null;
    },
  },
  mounted() {
    // 页面接口
    this.selectByCustRealId();
  },
  methods: {
    jumpToNotice(item) {
      localStorage.setItem('noticeType', item.value)
      this.$router.push({ path: '/NewsNotice/NewsList' })
    },
    // 掌上营业厅-首页
    async selectByCustRealId() {
      // 获取数据字典
      await store.dispatch('dict/GenerateDictory');
      // 获取数据字典
      await store.dispatch('dict/getSelectDictEnumByCode', 'STATUS_CODE');
      // 获取我的反馈数据字典
      await store.dispatch('dict/getSelectDictEnumByBusCode', 'BUS_CODE');
      await store.dispatch('user/savePathUrl', null);
      await store.dispatch('user/getHomeInfo');
      this.dataInfo = store.getters.homeInfo;
      // 我的用水的数据
      this.authorInfo = this.dataInfo.myWaterList;
      // 我的办理条目数
      this.businessNum = this.dataInfo.myBusCount;
      // 我的反馈条目数
      this.feedBackNum = this.dataInfo.myFeedBackCount;
      // 我的全部合同目数
      this.contractNum = this.dataInfo.allContractNum;
      // 我的未签合同目数
      this.unsignNum = this.dataInfo.unsignContractNum;
    },
    // 掌上营业厅-我的用水
    async selectMyWater() {
      const { status, resultData } = await selectMyWater();
      if (status === 'complete') {
        this.authorInfo = resultData;
      }
    },
    // 我的办理-数目
    async selectBusHandlingPage() {
      let arg = {
        treatState: 'nh_00', // 处理中
        moduleCode: '',
        page: {
          size: 1,
          current: 5,
        },
      };
      const { status, resultData } = await selectBusHandlingPage(arg);
      if (status === 'complete' && resultData) {
        this.businessNum = Number(resultData.total);
      }
    },
    // 我的反馈-数目
    async selectProcessingCount() {
      const { status, resultData } = await selectProcessingCount();
      if (status === 'complete') {
        this.feedBackNum = resultData;
        // 获取我的反馈数据字典
        await store.dispatch('dict/getSelectDictEnumByBusCode', 'BUS_CODE');
      }
    },
    // 点击 -- 进入未认证已绑定
    bindFn() {
      this.$router.push({ name: 'Bind', path: '/Bind' });
    },
    // 快捷支付
    goToQuickPay() {
      this.$router.push({ path: '/QueryPay' });
    },
    // 点击 -- 进入已认证未绑定
    goToRealName() {
      this.$router.push({ name: 'CertifiedPage', path: '/CertifiedPage' });
    },
    gotoCardCertified() {
      console.log('totototo');
      this.$router.push({ path: '/CertifiedPage/CardCertifiedList' });
    },
    // 拨打电话
    showCallPhone() {
      this.showCall = true;
    },
    cancelTel(value) {
      this.showCall = false;
    },
    gotoSetting() {
      this.$router.push({ path: '/Setting' });
    },
  },
};
</script>

<style lang="less">
.home-page {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  overflow-x: hidden;
  // 未认证
  .home-top-content {
    // height: 165px;
    background: linear-gradient(to top, #cfe6ff, #fafafa);
    .home-header {
      padding: 16px 15px;
      height: 45px;
      .leftInfo {
        float: left;
        display: table-cell;
        .imgDiv {
          width: 44px;
          height: 44px;
          display: inline-block;
          vertical-align: middle;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }
        em {
          width: 72px;
          height: 21px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
          }
        }
        span {
          font-weight: 700;
          vertical-align: middle;
          font-family: PingFangSC-Medium;
          font-size: 17px;
          color: #142233;
          letter-spacing: -0.51px;
          vertical-align: middle;
          margin-right: 12px;
          margin-left: 10px;
        }
      }
      .rightSeting {
        float: right;
        font-size: 18px;
        padding-top: 8px;
        vertical-align: middle;
        em {
          width: 24px;
          height: 24px;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
          }
        }
        em:last-child {
          margin-left: 15px;
        }
      }
    }
    // 未绑定
    .noBind-info {
      margin: 0px 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #0061ff, #63c6ff);
      background: url('../../assets/pic_blue_2.png') no-repeat 0 0;
      background-size: cover;
      .noBind {
        padding-top: 30px;
        padding-left: 20px;
        padding-bottom: 18px;
        position: relative;
        .noBind-txt {
          p {
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0.22px;
            line-height: 21px;
            text-align: left;
            margin: 0px;
          }
          span {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #000000;
            letter-spacing: 0.18px;
            line-height: 21px;
            color: #ffffff;
            text-align: left;
            display: block;
          }
        }
        .noBind-quick-payment {
          position: absolute;
          top: 35px;
          right: 0px;
          text-align: left;
          height: 32px;
          line-height: 32px;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          width: 100px;
          float: right;
          text-align: center;
          background: #188cff;
          font-family: PingFangSC-Medium;
          vertical-align: middle;
          margin-top: 3px;
          em {
            display: inline-block;
            width: 12px;
            height: 13px;
            vertical-align: middle;
            position: relative;
            margin-bottom: 5px;
            img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0px;
              top: 0px;
            }
          }
          span {
            display: inline-block;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0.18px;
            padding-left: 0px;
            vertical-align: middle;
            margin: 0px 5px 5px 5px;
          }
          .arrowIcon {
            font-size: 12px;
            vertical-align: middle;
            margin-bottom: 5px;
            color: #ffffff;
          }
        }
      }
    }
    // 已绑定
    .bind-info {
      margin: 0px 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #0061ff, #63c6ff);
      background: url('../../assets/pic_blue_2.png') no-repeat 0 0;
      background-size: cover;
      .bind {
        // .bind-time-info {
        //   font-family: PingFangSC-Regular;
        //   color: rgba(255, 255, 255, 0.54);
        //   vertical-align: middle;
        //   text-align: left;
        //   padding-top: 16px;
        //   padding-left: 15px;
        //   font-size: 12px;
        //   letter-spacing: 0;
        //   .infoIcon {
        //     vertical-align: middle;
        //   }
        //   span {
        //     vertical-align: middle;
        //   }
        //   em {
        //     font-style: normal;
        //     vertical-align: middle;
        //   }
        // }
        .bind-pay-info {
          font-family: PingFangSC-Semibold;
          text-align: left;
          color: #ffffff;
          padding-bottom: 10px;
          p {
            font-size: 12px;
            padding-top: 11px;
            padding-left: 20px;
            margin: 0;
          }
          .bind-bottom-info {
            display: flex;
            justify-content: space-between;
            span {
              margin-bottom: 5px;
              padding-left: 20px;
              font-size: 32px;
              color: #ffffff;
              letter-spacing: 0;
            }
            .bind-quick-payment {
              height: 32px;
              line-height: 32px;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              width: 100px;
              float: right;
              margin-top: 8px;
              text-align: center;
              background: #188cff;
              font-family: PingFangSC-Medium;
              vertical-align: middle;
              em {
                display: inline-block;
                width: 12px;
                height: 13px;
                vertical-align: middle;
                position: relative;
                margin-bottom: 5px;
                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                }
              }
              span {
                display: inline-block;
                font-size: 12px;
                color: #ffffff;
                letter-spacing: 0.18px;
                padding-left: 0px;
                vertical-align: middle;
                margin-left: 5px;
                margin-right: 5px;
              }
              .arrowIcon {
                font-size: 12px;
                vertical-align: middle;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    .home-title-noInfo {
      margin: 0px 23px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #3481ff, #36b1ff);
      font-size: 14px;
      line-height: 28px;
      color: #ffffff;
      padding: 10px;
    }
  }
  // 已认证
  .home-top-content-realName {
    background: linear-gradient(to top, #665f4f, #3c4049);
    // height: 205px;
    .home-header {
      padding: 16px 15px;
      height: 45px;
      .leftInfo {
        float: left;
        display: table-cell;
        .imgDiv {
          width: 44px;
          height: 44px;
          display: inline-block;
          vertical-align: middle;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }
        em {
          width: 72px;
          height: 21px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
          }
        }
        span {
          font-weight: 700;
          vertical-align: middle;
          font-family: PingFangSC-Medium;
          font-size: 17px;
          color: #ffffff;
          letter-spacing: -0.51px;
          vertical-align: middle;
          margin-left: 10px;
          margin-right: 12px;
        }
      }
      .rightSeting {
        float: right;
        font-size: 18px;
        padding-top: 8px;
        vertical-align: middle;
        em {
          width: 24px;
          height: 24px;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
          }
        }
        em:last-child {
          margin-left: 15px;
        }
      }
    }
    // 已绑定
    .bind-info {
      margin: 0px 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #cd944d, #ebcf9c);
      background: url('../../assets/pic_golden_2.png') no-repeat 0 0;
      background-size: cover;
      .bind {
        .bind-time-info {
          font-family: PingFangSC-Regular;
          color: rgba(255, 255, 255, 0.54);
          vertical-align: middle;
          text-align: left;
          padding-top: 16px;
          padding-left: 15px;
          font-size: 12px;
          letter-spacing: 0;
          .infoIcon {
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
          }
          em {
            font-style: normal;
            vertical-align: middle;
          }
        }
        .bind-pay-info {
          font-family: PingFangSC-Semibold;
          text-align: left;
          color: #ffffff;
          padding-bottom: 10px;
          p {
            font-size: 12px;
            padding-top: 11px;
            padding-left: 20px;
            margin: 0px;
          }
          .bind-bottom-info {
            display: flex;
            justify-content: space-between;
            span {
              margin-bottom: 5px;
              padding-left: 20px;
              font-size: 32px;
              color: #ffffff;
              letter-spacing: 0;
            }
            .bind-quick-payment {
              height: 32px;
              line-height: 32px;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              width: 100px;
              float: right;
              margin-top: 8px;
              text-align: center;
              background: #d5a864;
              font-family: PingFangSC-Medium;
              vertical-align: middle;
              em {
                display: inline-block;
                width: 12px;
                height: 13px;
                vertical-align: middle;
                position: relative;
                margin-bottom: 5px;
                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                }
              }
              span {
                display: inline-block;
                font-size: 12px;
                color: #ffffff;
                letter-spacing: 0.18px;
                padding-left: 0px;
                vertical-align: middle;
                margin-left: 5px;
                margin-right: 5px;
              }
              .arrowIcon {
                font-size: 12px;
                vertical-align: middle;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    // 未绑定
    .noBind-info {
      margin: 0px 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #cd944d, #ebcf9c);
      background: url('../../assets/pic_golden_2.png') no-repeat 0 0;
      background-size: cover;
      .noBind {
        padding-top: 30px;
        padding-left: 20px;
        padding-bottom: 18px;
        position: relative;
        .noBind-txt {
          p {
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0.22px;
            line-height: 21px;
            text-align: left;
            margin: 0px;
          }
          span {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #000000;
            letter-spacing: 0.18px;
            line-height: 21px;
            color: #ffffff;
            text-align: left;
            display: block;
          }
        }
        .noBind-quick-payment {
          position: absolute;
          top: 35px;
          right: 0px;
          text-align: left;
          height: 32px;
          line-height: 32px;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          width: 100px;
          float: right;
          text-align: center;
          background: #d5a864;
          font-family: PingFangSC-Medium;
          vertical-align: middle;
          margin-top: 3px;
          em {
            display: inline-block;
            width: 12px;
            height: 13px;
            vertical-align: middle;
            position: relative;
            margin-bottom: 5px;
            img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0px;
              top: 0px;
            }
          }
          span {
            display: inline-block;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0.18px;
            padding-left: 0px;
            vertical-align: middle;
            margin: 0px 5px 5px 5px;
          }
          .arrowIcon {
            font-size: 12px;
            vertical-align: middle;
            margin-bottom: 5px;
            color: #ffffff;
          }
        }
      }
    }
    .home-title-noInfo {
      margin: 0px 23px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: linear-gradient(to right, #3481ff, #36b1ff);
      font-size: 14px;
      line-height: 28px;
      color: #ffffff;
      padding: 10px;
    }
  }
  // 已缴清状态
  .payUp {
    padding-top: 20px;
    padding-bottom: 25px;
    text-align: center;
    color: #fff;
  }
  .home-bottom-content {
    margin-top: -14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .mgr20 {
    margin-right: 20px;
  }

  .van-grid-item__content {
    padding: 0px;
  }
  .van-cell__title {
    span {
      width: 100%;
      font-weight: 700;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #222f45;
      letter-spacing: 0;
    }
  }
  .van-cell::after {
    content: '';
    border-bottom: 0px solid transparent;
  }
  .van-cell__value {
    position: relative;
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #596c86;
      letter-spacing: 0;
    }
    em {
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: #ffffff;
      background-color: #ff5858;
      border-radius: 100px;
      font-size: 12px;
      font-style: normal;
      text-align: center;
      position: absolute;
      color: #ffffff;
      top: 2px;
      right: 0px;
    }
  }
}

.tel-text {
  display: inline-block;
  width:90px;
  font-size: 14px;
  color: #fff;
  float: left;
  line-height: 24px;
}

.tel-text-no{
  display: inline-block;
  width:90px;
  font-size: 14px;
  color: #333;
  float: left;
  line-height: 24px;
}
.tab-nav {
  width: 100%;
  .tab-active {
    font-weight: 500;
    color: #4884F0;
    border-bottom: 2px solid #4884F0;
  }
}

</style>
