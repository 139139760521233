/*
 * @Author: yangliao
 * @Date: 2021-03-17 15:26:37
 * @LastEditTime: 2021-06-09 11:50:43
 * @LastEditors: yangliao
 * @Description: 业务数据
 * @FilePath: /netHallOfficialAccounts/src/views/Home/components/business.js
 */

import myWater_First from '@/assets/icon/ic_blue_自助抄表@2x.png';
import myWater_Second from '@/assets/icon/ic_blue_充值缴费@2x.png';
import myWater_Third from '@/assets/icon/ic_blue_电子发票@2x.png';
import myWater_Fourth from '@/assets/icon/ic_blue_用水统计@2x.png';
import myWater_Fifth from '@/assets/icon/ic_blue_用户绑定@2x.png';
import myWaterOutlets from '@/assets/icon/ic_营业网点_蓝.png';
import myWater_Golden_First from '@/assets/icon/ic_golden_自助抄表@2x.png';
import myWater_Golden_Second from '@/assets/icon/ic_golden_充值缴费@2x.png';
import myWater_Golden_Third from '@/assets/icon/ic_golden_电子发票@2x.png';
import myWater_Golden_Fourth from '@/assets/icon/ic_golden_用水统计@2x.png';
import myWater_Golden_Fifth from '@/assets/icon/ic_golden_用户绑定@2x.png';
import myWaterOutletsReal from '@/assets/icon/ic_营业网点_金.png';

import business_First from '@/assets/icon/ic_用水报装@2x.png';
import business_Second from '@/assets/icon/ic_过户更名@2x.png';
import business_Name from '@/assets/icon/ic_name.png';
import business_Third from '@/assets/icon/ic_报事报修@2x.png';
import business_Fourth from '@/assets/icon/ic_water.png';
import business_Fifth from '@/assets/icon/ic_阶梯人口@2x.png';
import business_Sixth from '@/assets/icon/ic_低保申请@2x.png';
import business_Seventh from '@/assets/icon/ic_水表报停@2x.png';
import business_Eighth from '@/assets/icon/ic_水表复装@2x.png';
import business_Ninth from '@/assets/icon/ic_销户@2x.png';
import business_Tenth from '@/assets/icon/ic_移管申请@2x.png';
import business_Eleventh from '@/assets/icon/ic_水表迁移@2x.png';
import business_Twelfth from '@/assets/icon/ic_口径变更@2x.png';
import business_Change from '@/assets/icon/ic_change.png';

const girdIcon = [
    // {
    //   name: '自助抄表',
    //   icon: myWater_First,
    //   badgeInfo: '待抄表',
    //   pathUrl: '/SelfMeterRead',
    //   componentName: 'SelfMeterRead'
    // },
    {
        name: '充值缴费',
        icon: myWater_Second,
        pathUrl: '/WaterAccount/PreStorage',
        componentName: 'PreStorage',
    },
    { name: '电子发票', icon: myWater_Third, pathUrl: '/Invoice', componentName: 'Invoice' },
    {
        name: '用水统计',
        icon: myWater_Fourth,
        pathUrl: '/WaterStatistics',
        componentName: 'WaterStatistics',
    },
    { name: '用户绑定', icon: myWater_Fifth, pathUrl: '/Bind', componentName: 'Bind' },
    {
        name: '营业水厂',
        icon: myWaterOutlets,
        pathUrl: '/PublicNews/BusinessOffice',
        componentName: 'BusinessOffice',
    }
];

const girdGoldenIcon = [
    // {
    //   name: '自助抄表',
    //   icon: myWater_Golden_First,
    //   badgeInfo: '待抄表',
    //   pathUrl: '/SelfMeterRead',
    //   componentName: 'SelfMeterRead'
    // },
    {
        name: '充值缴费',
        icon: myWater_Golden_Second,
        pathUrl: '/WaterAccount/PreStorage',
        componentName: 'PreStorage',
    },
    {
        name: '电子发票',
        icon: myWater_Golden_Third,
        pathUrl: '/Invoice',
        componentName: 'Invoice',
    },
    {
        name: '用水统计',
        icon: myWater_Golden_Fourth,
        pathUrl: '/WaterStatistics',
        componentName: 'WaterStatistics',
    },
    { name: '用户绑定', icon: myWater_Golden_Fifth, pathUrl: '/Bind', componentName: 'Bind' },
    {
        name: '营业水厂',
        icon: myWaterOutletsReal,
        pathUrl: '/PublicNews/BusinessOffice',
        componentName: 'BusinessOffice',
    }
];

const businessIcon = [
    { name: '用水报装', icon: business_First, pathUrl: '/Business/Expand' },
    { name: '更名过户', icon: business_Second, pathUrl: '/Business/Transfer' },
    // { name: '更名', icon: business_Name, pathUrl: '/Business/RenamedNotice' },
    // { name: '报事报修', icon: business_Third, pathUrl: '/Business/RepairNotice' },
    // { name: '报事报修', icon: business_Third, pathUrl: '/Business/Repair' },
    { name: '变更阶梯人口', icon: business_Fifth, pathUrl: '/Business/PopulationNotice' },
    { name: '用水性质变更', icon: business_Fourth, pathUrl: '/Business/NatureTurnNotice' },
    // { name: '低保申请', icon: business_Sixth, pathUrl: '/Business/SubsistenceNotice' },
    { name: '水表报停', icon: business_Seventh, pathUrl: '/Business/WaterStopNotice' },
    { name: '水表复装', icon: business_Eighth, pathUrl: '/Business/WaterResumeNotice' },
    // { name: '销户', icon: business_Ninth, pathUrl: '/Business/WaterCloseNotice' },
    // { name: '信息变更', icon: business_Change, pathUrl: '/Business/NewsTurnNotice' },
    // { name: 'test', icon: business_Change,target:'outOpen', pathUrl: 'https://payapp.weixin.qq.com/life/index?__hash=/agency/1/city/210500/companyid/JF-EPAY2021050803804/billkey/16027323' }
    // { name: '移管申请', icon: business_Tenth, pathUrl: '/Business/WaterpipeMoveNotice' },
    // { name: '水表迁移', icon: business_Eleventh, pathUrl: '/Business/WaterMeterMoveNotice' },
    // { name: '口径变更', icon: business_Twelfth } // 本期不做
    // { name: '开票信息变更', icon: business_Name, pathUrl: '/Business/NewsTurnNotice' },
];
export { girdIcon, girdGoldenIcon, businessIcon };
