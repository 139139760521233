<!--
 * @Author: your name
 * @Date: 2020-10-17 21:05:56
 * @LastEditTime: 2021-07-14 15:48:13
 * @LastEditors: yangliao
 * @Description: 我的办理
 * @FilePath: /netHallOfficialAccounts/src/views/Home/module/businessProcess.vue
-->
<template>
  <div class="business-process">
    <div class="business-process-content">
      <van-cell title="业务办理" is-link @click="gotoBusinessManagement">
        <span :style="businessNum > 0 ? 'margin-right: 25px' : ''">我的办理</span>
        <em v-if="businessNum > 0">{{ businessNum }}</em>
      </van-cell>
      <div class="business-info">
        <van-grid column-num="4" :border="false">
          <van-grid-item
            use-slot
            v-for="(item, index) in dataInfo"
            :key="index"
            @click="waterService(item)"
          >
            <!-- <em :class="item.name === '用水性质' || item.name=== '口径变更' ? 'gray' : ''"><img :src="item.icon" alt="" /></em> -->
            <em><img :src="item.icon" alt=""/></em>
            <span class="name">{{ item.name }}</span>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';

export default {
  name: 'BusinessProcess',
  props: {
    dataInfo: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
    businessNum: {
      type: Number,
      require: true,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
    }),
  },
  methods: {
    gotoBusinessManagement() {
      this.$router.push({ path: '/BusinessManagement' });
    },
    // 用水业务跳转
    waterService(data) {
      if (data && data.pathUrl) {
        /* 清空业务全局变量 */
        store.dispatch('user/setBusinessNews', null);
        if (data.target === 'outOpen') {
          window.location.href = data.pathUrl;
        } else {
          if (this.homeInfo && this.homeInfo.isCertified) {
            this.$router.push({
              path: data.pathUrl,
            });
          } else {
            this.handerDialog();
          }
        }
      }
      // Dialog.confirm({
      //   title: ``,
      //   message: `暂未开通此功能`,
      //   showCancelButton: false,
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#3C7EF0'
      // });
    },
    /* 认证弹框 */
    handerDialog() {
      Dialog.confirm({
        title: `未认证！`,
        message: `业务需要进行认证之后才能办理，请您先进行认证！`,
        confirmButtonText: '认证',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '取消',
        cancelButtonColor: '#767E8C',
      })
        .then(() => {
          // 认证
          this.$router.push({ name: 'CertifiedPage', path: '/CertifiedPage' });
        })
        .catch(() => {
          // 取消
        });
    },
  },
};
</script>

<style lang="less" scoped>
.business-process {
  background-color: #ffffff;

  .business-process-content {
    text-align: left;
    padding-top: 10px;

    .business-info {
      width: 100%;
      padding-bottom: 16px;
      background-color: #fff;

      em {
        display: inline-block;
        width: 44px;
        height: 44px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .gray {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
      }

      .name {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #5c6775;
        letter-spacing: 0;
      }
    }
  }
}
</style>
